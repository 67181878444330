import { Box, Grid, IconButton, Tooltip, Typography } from "@mui/material";
import React, { useContext, useState } from "react";
import ApiPaginateSearch from "../component/ApiPaginateSearch";
import { ddmmyy, dateToTime } from "../utils/DateUtils";

// Icons
import LaptopIcon from "@mui/icons-material/Laptop";
import AppleIcon from "@mui/icons-material/Apple";
import AndroidIcon from "@mui/icons-material/Android";
import ApiEndpoints from "../network/ApiEndPoints";
import { CustomStyles } from "../component/CustomStyle";
import { android2, macintosh2, windows2, linux2 } from "../iconsImports";
import AuthContext from "../store/AuthContext";
import { wrap } from "gsap";
import RefreshComponent from "../component/RefreshComponent";
import CachedIcon from "@mui/icons-material/Cached";
import { capitalize1 } from "../utils/TextUtil";
let refresh;
const LoginHistory = () => {
  const [apiData, setApiData] = useState([]);
  const [query, setQuery] = useState("");
  const authCtx = useContext(AuthContext);
  const user = authCtx?.user;
  const columns = [
    {
      name: "User ",
      selector: (row) => <>{capitalize1(user.establishment)}</>,
    },
    {
      name: "Login At",
      selector: (row) => (
        <div>
          {ddmmyy(row.created_at)} {dateToTime(row.created_at)}
        </div>
      ),
    },
    {
      name: "Login Ip",
      selector: (row) => (
        <Tooltip title={row.ip}>
          <div style={{ textAlign: "center" }}>{row.ip}</div>
        </Tooltip>
      ),
    },
    {
      name: "Login Device",
      selector: (row) => {
        let icon;

        if (row.device.toLowerCase().includes("windows")) {
          icon = (
            <img
              src={windows2}
              style={{ width: "22px" }}
              alt="description of image"
            />
          );
        } else if (row.device.toLowerCase().includes("android")) {
          icon = (
            <img
              src={android2}
              style={{ width: "22px" }}
              alt="description of image"
            />
          );
        } else if (row.device.toLowerCase().includes("mac")) {
          icon = (
            <img
              src={macintosh2}
              style={{ width: "22px" }}
              alt="description of image"
            />
          );
        } else if (row.device.toLowerCase().includes("linux")) {
          icon = (
            <img
              src={linux2}
              style={{ width: "22px" }}
              alt="description of image"
            />
          );
        } else {
          icon = <LaptopIcon sx={{ color: "blue", width: "22px" }} />;
        }

        return (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              fontSize: "12px",
              textAlign: "justify",
              gap: 2,
            }}
          >
            {icon}
            <Typography>{row.device}</Typography>
          </Box>
        );
      },
      width: "560px",
      wrap: true,
      center: false,
    },
  ];

  function refreshFunc(setQuery) {
    setQuery("");
    if (refresh) refresh();
  }

  return (
    <Box>
      <Grid container>
        <Grid item md={12} sm={12} xs={12}>
          <ApiPaginateSearch
            actionButtons={
              <Grid
                item
                md={12}
                sm={12}
                xs={12}
                sx={{
                  display: "flex",
                  justifyContent: { md: "end", xs: "start" },
                  alignItems: "center",
                  pr: 2,
                  mt: { md: 0, xs: 2, sm: 2 },
                }}
              >
                <Tooltip title="refresh">
                  <IconButton
                    aria-label="refresh"
                    sx={{
                      color: "#0F52BA",
                    }}
                    onClick={() => {
                      refreshFunc(setQuery);
                    }}
                  >
                    <CachedIcon className="refresh-purple" />
                  </IconButton>
                </Tooltip>
              </Grid>
            }
            showSearch={true}
            isFilterAllowed
            apiEnd={ApiEndpoints.GET_LOGIN_HISTORY}
            columns={columns}
            apiData={apiData}
            setApiData={setApiData}
            tableStyle={CustomStyles}
            queryParam={query ? query : ""}
            returnRefetch={(ref) => {
              refresh = ref;
            }}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default LoginHistory;
